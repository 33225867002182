@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  margin: 0;
  width: 100%;
  height: 100vh;
  height: 100%;
  background: #4185f4;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  color: #FFFF; }
  .main-container .upload-buttons-row {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 50px;
    background: #FFFF;
    color: #000; }
    .main-container .upload-buttons-row .upload-buttons-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 20px; }
      .main-container .upload-buttons-row .upload-buttons-col .upload-buttons-col-title {
        margin: 10px;
        margin-bottom: 20px; }
  .main-container .main-container-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .main-container .main-container-body .main-container-body-generate {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 40px;
      margin: 20px;
      font-size: 15px;
      font-weight: 900;
      border: none;
      border-radius: 5px;
      color: #4185f4;
      background: #FFFF;
      cursor: pointer; }

