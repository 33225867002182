@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import "./assets/Colors.scss"; 

body {
  margin : 0;
  width  : 100%;
  height : 100vh;
  height : 100%;
  background   : $blue;
  font-family : 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
