@import "../../assets/Colors.scss"; 

.main-container{
    display        : flex;
    flex-direction : column;
    align-items    : center;
    height         : 100vh;
    color          : $white;

    .upload-buttons-row{
        display         : flex;
        flex-direction  : row;
        width           : 90%;
        margin          : 50px;
        background      : $white;
        color           : $black;

        .upload-buttons-col{
            display         : flex;
            flex-direction  : column;
            justify-content : center;
            align-items     : center;
            width           : 100%;
            margin          : 20px;

            .upload-buttons-col-title{
                margin        : 10px;
                margin-bottom : 20px;
            }
        }
    }
    .main-container-body{
        display         : flex;
        flex-direction  : column;
        justify-content : center;
        align-items     : center;

        .main-container-body-generate{
            display         : flex;
            align-items     : center;
            justify-content : center;
            width           : 200px;
            height          : 40px;
            margin          : 20px;
            font-size       : 15px;
            font-weight     : 900;
            border          : none;
            border-radius   : 5px;
            color           : $blue;
            background      : $white;
            cursor          : pointer;
        }
    }
}